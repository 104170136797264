import { Component, OnInit } from '@angular/core';
import { Etablissement } from 'src/app/model/etablissement';
import { SharingdataService } from 'src/app/service/sharingdata.service';
import { Router } from "@angular/router";
import { RedirectToAARService } from 'src/app/service/redirect-to-aar.service';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.css']
})
export class ConnexionComponent implements OnInit {

  showLastConnetion = false;
  disableBtnConnetion = true
  isProfilSelected = false
  isEtablissementSelected = false;
  etablissementSelected!: Etablissement
  profilSelecetd!: string
  typeEtabSelected!: string
  isChecked = false
  elementLocaleStorage: string[] = new Array()
  elementLocaleStorageValue: string[] = new Array()

  constructor(private sharingDataService: SharingdataService,
              private readonly router: Router,
              private redirectToAar:RedirectToAARService) { }

  ngOnInit(): void {
    this.loadDataFromStorage()
    this.sharingDataService.selectedProfil.subscribe(profil => {
      if (profil) {
        this.profilSelecetd = profil
        this.isProfilSelected = true
      }
    })
    this.sharingDataService.selectedEtab.subscribe(etablissement => {
      if (!(etablissement?.nomEtablissement && etablissement?.nomCommune && etablissement?.idEtablissementUai
        && etablissement?.codePostal && etablissement?.codeInseeCommune)) {
        this.isEtablissementSelected = false
      }
      if (etablissement?.idEtablissementUai) {
        this.etablissementSelected = etablissement
        this.isEtablissementSelected = true
      }
    })
    this.sharingDataService.selectedTypeEtab.subscribe(typeEtab => {
      this.typeEtabSelected = typeEtab
    })
  }

  loadDataFromStorage() {
    Object.keys(localStorage).forEach(el => {
      let dateList = el.split('_')
      let valueStored=localStorage.getItem(el) as string
      if (dateList && dateList.length == 2 && dateList[0] == 'wayfNative') {
        this.showLastConnetion = true
        if(!this.elementLocaleStorageValue.includes(valueStored)){
          this.elementLocaleStorageValue.push(localStorage.getItem(el) as string)
        }
        if (!this.elementLocaleStorage.includes(el)) {
          this.elementLocaleStorage.push(el)
        }
      }

    })
    this.sharingDataService.changeValueStorage(this.elementLocaleStorageValue)
  }
  delegateAuthent() {
    this.loadDataFromStorage()
    //TODO Anouar: gestion des doublons
    if (this.isChecked) {
      let valueObject = JSON.stringify({ "etablissement": this.etablissementSelected, "profil": this.profilSelecetd, "typeEtab": this.typeEtabSelected }).toString()
      if (!this.controlIfValueIsPresentInLocalStorageValue(this.etablissementSelected.nomEtablissement, this.profilSelecetd)) {
        if (this.elementLocaleStorage.length === 3) {
          this.elementLocaleStorage.sort()
          console.log(this.elementLocaleStorage)
          let lastElement = this.elementLocaleStorage.shift()
          if (lastElement) {
            let elementDelete = localStorage.getItem(lastElement);
            if (elementDelete != null) {
              let elementDeleteObj = JSON.parse(elementDelete);
              let elementDeletaProfilObj = elementDeleteObj['profil'];
              let elementDeleteEtabObj: Etablissement = elementDeleteObj['etablissement'] as Etablissement;
              this.sharingDataService.dataLocalStorage.subscribe((res: string[]) => {
                res.forEach((etab, index) => {
                  let objSharinglocalDataStorage = JSON.parse(etab);
                  let objSharinglocalDataStorageProfil = objSharinglocalDataStorage['profil'];
                  let objSharinglocalDataStorageEtab: Etablissement = objSharinglocalDataStorage['etablissement'] as Etablissement;
                  if(objSharinglocalDataStorageEtab.nomEtablissement === elementDeleteEtabObj.nomEtablissement &&
                    objSharinglocalDataStorageProfil === elementDeletaProfilObj) {
                    delete res[index];
                  }
                });
              });
            }
            localStorage.removeItem(lastElement)
          }
          localStorage.setItem('wayfNative_' + Date.now().toString(), valueObject)
        }
        else if (this.elementLocaleStorage.length <= 3) {
          localStorage.setItem('wayfNative_' + Date.now().toString(), valueObject)
        }
      }
    }
    //TODO call AAR
    this.redirectToAar.goToAAR(this.etablissementSelected?.idEtablissementUai,this.profilSelecetd)
  }
  checkInput() {
    this.isChecked = !this.isChecked
  }

  loadFavoris() {
    this.sharingDataService.reset("")
    console.log(this.elementLocaleStorageValue)
    this.router.navigate(['/favoris']);
  }

  controlIfValueIsPresentInLocalStorageValue(nomEtablissementSelected: string, profilSelected: string): boolean {
    let isExist: boolean = false;
    this.elementLocaleStorageValue.forEach(elementLocalStorage => {
      let elementStorage = JSON.parse(elementLocalStorage);
      let elementSotageProfil = elementStorage['profil'];
      let elementStorageEtab: Etablissement = elementStorage['etablissement'] as Etablissement;
      if(elementSotageProfil === profilSelected && elementStorageEtab.nomEtablissement === nomEtablissementSelected) {
        isExist = true;
      }
    })
    return isExist;
  }

  getDeletedEtab() {
    this.isEtablissementSelected = false
  }
}
