<header role="banner" class="fr-header">
    <div class="fr-header__body">
        <div class="fr-container">
            <div class="fr-header__body-row">
                    <div class="fr-header__brand-top">
                        <img alt= "logo Ministère Éducation Nationale" class="fr-responsive-img image-header " src="../../../assets/img/logo-ministere.svg">
                        <span class="line"></span>
                        <div class="fr-header__operator">
                                <img class="fr-responsive-img image-header-gar"  src="../../../assets/img/logo-gar.svg" alt="logo GAR" />
                                <!-- L’alternative de l’image (attribut alt) doit impérativement être renseignée et reprendre le texte visible dans l’image -->
                        </div>
                        <div class="fr-header__navbar">
                            <a class="fr-link" [routerLink]="['/aide']" attr.aria-label="{{'HEADER.BUTTON_AIDE' | translate}}">
                                <img alt="image point interrogation pour aide"src="../../../assets/img/aide.svg">
                                <span class="icon">{{'HEADER.BUTTON_AIDE' | translate }}</span>
                              </a>
                        </div>
                      <!-- lien d'évitement -->
                      <button class="evitement" tabindex="0" aria-label="Aller au contenu du formulaire" (onclick)="sendFocusToProfil()" (keyup.enter)="sendFocusToProfil()">Aller au contenu</button>
                    </div>
                <div class="fr-header__tools">
                    <div class="fr-header__tools-links">
                        <ul class="fr-btns-group">
                            <li >

                                <a class="fr-link" [routerLink]="['/aide']" attr.aria-label="{{'HEADER.BUTTON_AIDE' | translate}}">
                                    <img alt="image point interrogation pour aide"src="../../../assets/img/aide.svg">
                                    <span class="icon">{{'HEADER.BUTTON_AIDE' | translate }}</span>
                                  </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
